<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      size="lg"
      centered
      title="Thêm công thức tính điểm ưu tiên"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="admissionRoundId">
              <template v-slot:label>
                Đợt tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Đợt tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionRoundId"
                  :options="admissionRounds"
                  :reduce="option => option.value"
                  @input="readAdmissionForm"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="admissionFormId">
              <template v-slot:label>
                Phương thức tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Phương thức tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionFormId"
                  :options="dataListAdmissionForm"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên công thức tính điểm ưu tiên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên công thức tính điểm ưu tiên"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  name="name"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã công thức <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã công thức"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.code"
                  name="code"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="description">
              <template v-slot:label>
                Mô tả
              </template>
              <b-form-textarea
                id="description"
                v-model="targetItem.description"
                name="description"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="formula">
              <template v-slot:label>
                Công thức tính điểm ưu tiên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Công thức tính điểm ưu tiên"
                rules="required"
              >
                <b-form-textarea
                  id="formula"
                  v-model="targetItem.formula"
                  name="formula"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="score">
              <template v-slot:label>
                Điểm tối thiểu áp dụng công thức <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Điểm tối thiểu áp dụng công thức"
                rules="required"
              >
                <b-form-input
                  id="score"
                  v-model="targetItem.score"
                  name="score"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="12">
          <span class="text-right ml-1 mb-1" style="color: red">
            <feather-icon icon="CheckIcon" /> Thêm chú thích tham số trong công thức
          </span>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form
              ref="formRef"
            >
              <b-row
                v-for="(item, index) in formulaResources"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <b-col md="4">
                  <b-form-group label-for="wildcard">
                    <template v-slot:label>
                      Tham số <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Tham số"
                      rules="required"
                    >
                      <b-form-input
                        id="wildcard"
                        v-model="item.wildcard"
                        name="wildcard"
                        :state="getElementState(errors)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-for="admissionSubjectId">
                    <template v-slot:label>
                      Điểm tương ứng với tham số <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Điểm tương ứng với tham số"
                      rules="required"
                    >
                      <v-select
                        id="admissionSubjectId"
                        v-model="item.admissionSubjectId"
                        :options="listAdmissionSubjectId"
                        :reduce="(option) => option.value"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="2"
                  md="2"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>Xóa</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mb-1"
              variant="success"
              @click="repeatAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Thêm mới</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetItem.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import ta from 'vue2-datepicker/locale/es/ta'

export default {
  name: 'Save',
  components: {
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        admissionRoundId: null,
        admissionFormId: null,
        description: null,
        formula: null,
        score: null,
        name: '',
        code: '',
        status: 1,
      },
      formulaResources: [{
        id: 1,
        formulaPriorityScoreId: null,
        wildcard: null,
        admissionSubjectId: null,
      }],
      required,
      listAdmissionSubjectId: [],
      nextId: 1,
    }
  },
  computed: {
    ta() {
      return ta
    },
    ...mapGetters({
      statuses: 'admissionRound/statuses',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
      dataListSubject: 'factAdmissionSubject/dataListSubject',
    }),
    statusOptions() {
      return this.statuses
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions({
      create: 'formulaPriorityScore/create',
      read: 'formulaPriorityScore/read',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getListSubject: 'factAdmissionSubject/getListSubject',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId })
      await this.getListSubject()
      this.listAdmissionSubjectId = this.dataListSubject
    },
    async readAdmissionForm(id) {
      await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: id })
    },
    removeItem(index) {
      this.formulaResources.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formRef.scrollHeight)
      })
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        admissionRoundId: null,
        admissionFormId: null,
        description: null,
        formula: null,
        name: '',
        code: '',
        status: 1,
      }
      this.formulaResources = [{
        id: 1,
        formulaPriorityScoreId: null,
        wildcard: null,
        admissionSubjectId: null,
      }]
      this.nextId = 2
    },
    repeatAgain() {
      this.formulaResources.push({
        id: this.nextId += this.nextId,
        formulaPriorityScoreId: null,
        wildcard: null,
        admissionSubjectId: null,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.create({
            admissionRoundId: this.targetItem.admissionRoundId,
            admissionFormId: this.targetItem.admissionFormId,
            description: this.targetItem.description,
            formula: this.targetItem.formula,
            score: this.targetItem.score,
            status: this.targetItem.status,
            name: this.targetItem.name,
            code: this.targetItem.code,
            formulaParameterList: this.formulaResources,
          })
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
